//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from 'quasar'
export default {
    data() {
        return {
            loading: false,
            loadingtab2: false,
            loadingDetail: false,
            productCode: null,
            matnr_code: null,
            matnr_List: [],
            showGoodsSwitch: false,
            tab: "size",
            productData: null,
            curData: null,
            shopData: [],
            productMaster: {
                matnr: '',
                matnrt: '',
                yuexiao: 0,
                leixiao: 0,
                kucun: 0

            },
            tableHeader: {
                size: {
                    row1: '尺码'
                },
                shop: {
                    row1: '门店'
                }
            },
            shopColumns: [{
                name: 'storet',
                required: true,
                label: '门店',
                align: 'left',
                field: 'storet'
            },
            { name: 'salesMonth', align: 'center', label: '月销', field: 'salesMonth', sortable: true },
            { name: 'sales', label: '累销', field: 'sales', sortable: true },
            { name: 'stock', label: '库存', field: 'stock' }
            ],
            pagination: {
                rowsPerPage: 0
            },
            showStoreDetail: false,
            storeId: ''
        }
    },
    created() {
        this.getList();
    },
    watch: {
        matnr_code(n, old) {
            console.log(n, old,999);
            if (old && n !== old) {
                this.getData()
                // if (this.tab == 'shop') {
                //     this.getCodeData()
                // }
                this.showGoodsSwitch = false
            }
        }
    },
    methods: {
        onSubmit() {
            console.log(this.productCode)
        },
        goBack() {
            this.$router.back();
        },
        getCodeData() {
            if (this.productData && this.matnr_code === this.productData[0].matnr) {
                return
            } else {
                this.productMaster = {
                    matnr: '',
                    matnrt: '',
                    yuexiao: 0,
                    leixiao: 0,
                    kucun: 0
                }
            }
            console.log(this.productData, this.matnr_code);

            var today = this.$moment().format('YYYYMMDD');
            // this.productCode = this.$route.query.code;
            var pdata = [ {
                'PARAMETER': 'Z0CALDAY_MVSM_003',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': today,
                'HIGH': '',
                'LOW_FLAG': '0'
            }, {
                'PARAMETER': 'Z0MATERIAL_CMOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': this.matnr_code,
                'HIGH': ''
            }, {
                'PARAMETER': 'ZZSALETYPE_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': 'P',
                'HIGH': '',
                'LOW_FLAG': '1'
            }, {
                'PARAMETER': 'ZZSALEKIND_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '',
                'HIGH': ''
            }, {
                'PARAMETER': 'ZXL_CMOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '',
                'HIGH': ''
            }, {
                'PARAMETER': 'ZZPPG_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '',
                'HIGH': ''
            }]
            this.loading = true

            this.axios.post('/consumer/bw/BwApi/qywxapp_getMatnrSalesAndStock', pdata).then(res => {
                this.loading = false
                if (res.data.result) {

                    console.log('qywxapp_getMatnrSalesAndStock', res.data.data)
                    this.productData = res.data.data;
                    for (var i = 0; i < this.productData.length; i++) {
                        this.productMaster.matnr = this.productData[i].matnr;
                        this.productMaster.matnrt = this.productData[i].matnrt;
                        this.productMaster.yuexiao += Number(this.productData[i].salesMonth);
                        this.productMaster.leixiao += Number(this.productData[i].sales);
                        this.productMaster.kucun += Number(this.productData[i].stock);
                    }
                }
            });
        },
        getShopData() {
            if (this.shopData.length > 0 && this.matnr_code === this.productData[0].matnr) {
                return
            } else {
                this.shopData = []
            }
            var today = this.$moment().format('YYYYMMDD');
            // this.productCode = this.$route.query.code;
            // console.log(this.productCode)
            var pdata = [{
                'PARAMETER': 'Z0CALDAY_MVSM_003',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': today,
                'HIGH': '',
                'LOW_FLAG': '0'
            }, {
                'PARAMETER': 'Z0MATERIAL_CMOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': this.matnr_code,
                'HIGH': ''
            }, {
                'PARAMETER': 'ZZSALETYPE_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': 'P',
                'HIGH': ''
            }, {
                'PARAMETER': 'ZZSALEKIND_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '',
                'HIGH': ''
            }, {
                'PARAMETER': 'ZXL_CMOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '',
                'HIGH': ''
            }, {
                'PARAMETER': 'ZZPPG_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '',
                'HIGH': ''
            }]
            let temp = LocalStorage.getItem('userOwnerShops');
            if (temp && temp != "" && temp != "null") {
            this.stores = temp.split(",");
            this.stores.forEach(item => {
                pdata.push({
                        "HIGH": "",
                        "LOW": item,
                        "LOW_FLAG": "0",
                        "OPTION": "EQ",
                        "PARAMETER": "ZZSTORENEW_MVOO_001",
                        "SIGN": "I",
                        'LOW_FLAG': '1'
                    })
            });
            } 


            this.loadingtab2 = true

            this.axios.post('/consumer/bw/BwApi/qywxapp_getMatnrSalesAndStockInStore', pdata).then(res => {
                this.loadingtab2 = false
                if (res.data.result) {
                    console.log('getMatnrSalesAndStockInStore', res.data.data)
                    this.shopData = res.data.data;

                }
            });
        },
        //获取商品列表
        getList() {
            this.productCode = this.$route.query.code;
            this.axios.get('/consumer/v1/wx/cp/sapMatnr/toSatnrAndSize?matnr='  + this.productCode).then(res => {
                if (res.data.code == 0) {
                    console.log('getMatnrList', res.data.data)
                    var data = res.data.data;
                    var matnr_arr = []
                    data.map(v =>{
                        if(matnr_arr.indexOf(v.matnr) == -1){
                            matnr_arr.push(v.matnr)
                            this.matnr_List.push({
                                label: v.matnr + '(' + v.maktx + ')',
                                value: v.matnr
                            })
                        }
                    })
                    console.log(matnr_arr);
                    this.matnr_code = matnr_arr[0]
                    //多商品添加商品选择
                    if(matnr_arr.length > 1){
                        this.showGoodsDialog()
                    }else if(matnr_arr.length ==1){
                            this.getData() 
                    }else{
                        alert('没有查询到相关产品')
                    } 
                }
            });
        },
        //商品选择弹窗
        showGoodsDialog() {
            this.showGoodsSwitch = true
        },
        closeGoodsDialog(e) {
            //第一次进入取消时继续请求
            if (!this.productData) {
                this.getData()
            }
        },
        changeGoods(val) {
            this.showGoodsSwitch = false
            this.matnr_code = val
        },
        getData(val) {
            console.log(val,this.tab,123);
            if (this.tab == 'size') {
                this.getCodeData()
            } else {
                this.getShopData()
            }
        },
        showStore(store) {
            this.storeId = store
            var today = this.$moment().format('YYYYMMDD');
            this.productCode = this.$route.query.code;
            console.log(this.productCode)
            var pdata = [{
                "HIGH": "",
                "LOW": store,
                "LOW_FLAG": "0",
                "OPTION": "EQ",
                "PARAMETER": "ZZSTORENEW_MVOO_001",
                "SIGN": "I",
                'LOW_FLAG': '0'
            }, {
                'PARAMETER': 'Z0CALDAY_MVSM_003',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': today,
                'HIGH': '',
                'LOW_FLAG': '0'
            }, {
                'PARAMETER': 'Z0MATERIAL_CMOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': this.productCode,
                'HIGH': '',
                'LOW_FLAG': '1'
            }, {
                'PARAMETER': 'ZZSALEKIND_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '',
                'HIGH': ''
            }, {
                'PARAMETER': 'ZXL_CMOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '',
                'HIGH': ''
            }, {
                'PARAMETER': 'ZZPPG_MVOO_001',
                'SIGN': 'I',
                'OPTION': 'EQ',
                'LOW': '',
                'HIGH': ''
            }]
            this.loadingDetail = true
            this.axios.post('/consumer/bw/BwApi/qywxapp_getMatnrSizeSalesAndStockInStore', pdata).then(res => {
                this.loadingDetail = false
                if (res.data.result) {
                    console.log('getMatnrSizeSalesAndStockInStore', res.data.data)
                    this.curData = res.data.data
                    //this.shopData = res.data.data;

                }
            });
            this.showStoreDetail = true
        }

    }
}
